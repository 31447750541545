@import "ngx-toastr/toastr";

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    color: #383838 !important;
    background-color: #f5f5f5 !important;
    font-family: "Lato", "Open Sans", sans-serif !important;
    // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"!important;

}


input,
button,
select {
    outline: none;
}

/* You can add global styles to this file, and also import other style files */

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ COLORS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$color-red: #ff0000;
$color-green: #00ff00;
$color-blue: #0000ff;
$color-violet: violet;
$color-grey: grey;
$color-pink: pink;

$fonts: (
    10,
    13,
    14,
    15,
    16
);

@each $value in $fonts {
    .font#{$value} {
        font-size: #{$value}px;
    }
}

$overflows: (
    "Hidden": hidden,
    "Auto": auto,
    "Unset": unset,
);

@each $key, $value in $overflows {
    .overflow#{$key} {
        overflow: $value !important;
    }
}

// custom SCSS start
$theme-color: #005BF0;
$theme-color-light: #ebf7f5;
$theme-color-error: #f1736a;
$theme-color-error-dark: #b3463f;
$theme-color-error-bg: #fdf2f1;
// $theme-color-light-gray: #f8f8fb;
$theme-color-light-gray: #f5f5f5;

$theme-color-text-dark: #000000;
$theme-color-dark-gray: #383838;
$theme-color-purple: #9476c9;
$color-danger: #DE350B;
$background-primary: #005BF0;


// table common colors
$border-color: #E6E6E6;
$thheader-font-color: #383838;
$tbody-font-color: #555555;
$chip-bg-color: #F5F5F5;
$primaryBlue-color: #005BF0;


// sections
$section-title-color: #26273B;

.btn-primary {
    background-color: $primaryBlue-color !important;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0;
    box-shadow: 0px 1px 2px 0px #0000000A !important;
    height: 40px !important;
    padding: 8px 16px 8px 16px;
    gap: 4px;
    border-radius: 4px 0px 0px 0px;
    border: none !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #ffffff !important;


    &:hover {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
            linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
        box-shadow: 0px 1px 2px 0px #0000000A;


    }

    &:focus {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
        border: 2px solid #005FCC;
        box-shadow: 0px 1px 2px 0px #0000000A;
    }


    &:active {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
        border: 2px solid #005FCC;
        box-shadow: 0px 1px 4px 0px #00000080 inset;

    }

    &:disabled,
    &.disabled {
        box-shadow: 0px 1px 2px 0px #0000000A;
        background-color: #F5F5F5 !important;
        border: 1px solid #E6E6E6 !important;
        color: #BBBBBB !important;
        pointer-events: none;
    }
}

.btn-primary-borderless-medium {
    background-color: transparent !important;
    height: 32px !important;
    gap: 4px;
    border-radius: 4px;
    border: none !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    color: $primaryBlue-color !important;
    padding: 4px 24px 4px 12px !important;
    gap: 4px !important;

    &:focus {
        border: 2px solid #005FCC !important;
        padding: 2px 22px 2px 10px !important;
    }

    &:disabled,
    &.disabled {
        box-shadow: 0px 1px 2px 0px #0000000A !important;
        color: #BBBBBB !important;
        pointer-events: none;
    }
}

.btn-primary-small {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
    font-weight: 700 !important;
    color: #ffffff !important;
    background-color: $primaryBlue-color !important;
    box-shadow: 0px 1px 2px 0px #0000000A !important;
    height: 32px !important;
    padding: 4px 16px 4px 16px !important;
    gap: 4px;
    border: none !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    min-width: max-content !important;

    &:hover {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
            linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0;
        box-shadow: 0px 1px 2px 0px #0000000A;


    }

    &:focus {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0;
        border: 2px solid #005FCC;
        box-shadow: 0px 1px 2px 0px #0000000A;
    }


    &:active {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0;
        border: 2px solid #005FCC;
        box-shadow: 0px 1px 4px 0px #00000080 inset;

    }


    &:disabled,
    &.disabled { 
        box-shadow: 0px 1px 2px 0px #0000000A;
        background-color: #F5F5F5 !important;
        border: 1px solid #E6E6E6 !important;
        color: #BBBBBB !important;
        pointer-events: none;
    }
}

.btn-secondary {
    background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
    box-shadow: 0px 1px 2px 0px #00000014 !important;
    height: 40px !important;
    padding: 8px 16px 8px 16px !important;
    gap: 4px;
    border-radius: 4px !important;
    border: 1px solid #BBBBBB !important;
    color: #555555 !important;
    font-size: 15px !important;
    font-weight: 700 !important;


    &:hover {
        background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%),
            linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%) !important;
        border: 1px solid #BBBBBB !important;
        box-shadow: 0px 1px 2px 0px #00000014 !important;
    }

    &:focus {
        background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
        border: 2px solid #005FCC !important;
        box-shadow: 0px 1px 2px 0px #00000014 !important;
    }

    &:active {
        background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
        border: 1px solid #BBBBBB !important;
        box-shadow: 0px 1px 4px 0px #00000080 inset !important;
    }

    &:disabled,
    &.disabled {
        background: #F5F5F5 !important;
        border: 1px solid #E6E6E6 !important;
        box-shadow: 0px 1px 2px 0px #00000014 !important;
        color: #BBBBBB !important;
        pointer-events: none;
    }
}

.btn-outline-secondary-small {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%), #f5f5f5 !important;
    box-shadow: 0px 1px 2px 0px #00000014;
    height: 32px !important;
    padding: 8px 16px 8px 16px !important;
    gap: 4px;
    border-radius: 4px !important;
    border: 1px solid #BBBBBB !important;
    color: #555555 !important;
    font-size: 13px !important;



    &:hover {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%),
            linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%), #f5f5f5;
        box-shadow: 0px 1px 2px 0px #0000000A;


    }

    &:focus {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%), #f5f5f5 !important;
        border: 2px solid #005FCC;
        box-shadow: 0px 1px 2px 0px #0000000A;

    }

    &:disabled,
    &.disabled {
        box-shadow: 0px 1px 2px 0px #0000000A;
        background: #F5F5F5;
        border: 1px solid #E6E6E6
    }
}

.abcde svg path {
    storke: green
}

.btn-danger {
    color: #fff !important;
    font-size: 15px;
    // background-color: $color-danger !important;
    border: none !important;
    background: linear-gradient(0deg, #DE350B, #DE350B),
            linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;
    box-shadow: 0px 1px 2px 0px #21222633 !important;

    &:hover {
        background: linear-gradient(0deg, #DE350B, #DE350B),
                linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%),
                linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%) !important;
        box-shadow: 0px 1px 2px 0px #21222633;
        border: none !important;
    }

    &:focus {
        border: 2px solid #005FCC !important;
        box-shadow: 0px 1px 2px 0px #21222633 !important;
        background: linear-gradient(0deg, #DE350B, #DE350B),
                linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;

    }

    &:active {
        background: linear-gradient(0deg, #DE350B, #DE350B),
            linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;
        box-shadow: 0px 1px 4px 0px #00000080 inset;
    }

    &:disabled,
    &.disabled {
        box-shadow: 0px 1px 2px 0px #0000000A !important;
        background: #F5F5F5 !important;
        border: 1px solid #E6E6E6 !important;
    }
}

.btn-outline-primary {
    color: #005BF0 !important;
    background: #ffffff !important;
    border-color: #005BF0 !important;
    height: 40px !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    font-weight: 700;
    font-size: 15px;

    &:hover {
        background: rgba(0, 91, 240, 0.08);
        border: 1px solid rgba(0, 91, 240, 1);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    }

    &:focus {
        border: 2px solid rgba(0, 95, 204, 1);
        color: #005BF0 !important;
        background: #ffffff !important;
        border-color: #005BF0 !important;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);

    }

    &:disabled,
    &.disabled {
        background: rgba(245, 245, 245, 1);
        border: 1px solid rgba(230, 230, 230, 1);
        color: rgba(187, 187, 187, 1);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    }
}

h3 {
    color: $theme-color !important;
}

.theme-color {
    color: $theme-color !important;
}

a {
    cursor: pointer;
}

.marginTop-20 {
    margin-top: 20px;
}

.rowGap10 {
    row-gap: 10px;
}

.margin0padding0 {
    margin: 0px !important;
    padding: 0px !important;
    background: #f5f5f5;
}

.font13 {
    font-size: 13px;
}

.font12 {
    font-size: 12px;
}

.font14 {
    font-size: 14px;
}

.font15 {
    font-size: 15px;
}

.bg-login {
    background: $theme-color-light;
    padding-left: 0px;
}

.w-12 {
    width: 12%
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-55 {
    width: 55%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85%;
}

.h-96 {
    height: 96%;
}

.footer-text {
    color: #acadc1;
}

.angular-google-recaptcha-container {
    width: 99%;
}

.bg-light-grey {
    background: $theme-color-light-gray;
}

.text-color-dark {
    color: $theme-color-text-dark !important;
}

.text-color-dark-grey{
    color: $theme-color-dark-gray
}

.otp-input {
    width: 40px !important;
    height: 40px !important;
    font-size: 24px !important;
    background: #ffffff;
    border-color: #acadc1 !important;
    outline: none;

    // &:focus {
    //   background: $theme-color-light;
    //   color: $theme-color;
    //   border-color: $theme-color !important;
    // }

    &:not(:last-child) {
        margin-right: 24px !important;
    }
}

.otp-input-focused {
    background: $theme-color-light;
    color: $theme-color;
    border-color: $theme-color !important;
}

.error {
    &.bg {
        background: $theme-color-error-bg !important;
    }

    &.color {
        color: $theme-color-error !important;

        &.dark {
            color: $theme-color-error-dark !important;
        }
    }

    &.border {
        border-color: $theme-color-error !important;
    }
}

// custom SCSS end

// sidebar and header SCSS start
/** ======================  base css ==============================**/
a:hover {
    text-decoration: none;
}

/** ======================  header ==============================**/
.header {
    background-color: #3c8dbc;
}

.header .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
    border-radius: 6px;
    top: 58px;
}

.header .user-image {
    float: left;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -2px;
}

.header .navbar-light .navbar-nav .nav-link {
    color: #fff;
}

.header .navbar-light .navbar-nav .nav-link:hover,
.header .navbar-light .navbar-nav .nav-link:focus {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
}

.header .fa.fa-fw.fa-bars {
    color: #fff;
}

.header .navbar-light .navbar-nav .nav-link {
    color: #fff;
    padding: 10px 20px;
    position: relative;
}

.header li>a>.label {
    position: absolute;
    top: 9px;
    right: 7px;
    text-align: center;
    font-size: 9px;
    padding: 2px 3px;
    line-height: 0.9;
    background-color: #333;
    border-radius: 0.25em;
}

.header li>a:after {
    display: none;
}

.header-ul {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #ffffff;
    padding: 7px 10px;
    border-bottom: 1px solid #f4f4f4;
    color: #333;
    font-size: 14px;
}

.navbar-nav>.notifications-menu>.dropdown-menu,
.navbar-nav>.messages-menu>.dropdown-menu,
.navbar-nav>.tasks-menu>.dropdown-menu {
    width: 280px;
    padding: 0 0 0 0;
    margin: 0;
    top: 100%;
}

.navbar-nav>.messages-menu>.dropdown-menu li .menu>li>a>div>img {
    margin: auto 10px auto auto;
    width: 40px;
    height: 40px;
}

.navbar-nav>.messages-menu>.dropdown-menu li .menu>li>a,
.navbar-nav>.notifications-menu>.dropdown-menu li .menu>li>a {
    margin: 0;
    padding: 10px 10px;
    display: block;
    white-space: nowrap;
    border-bottom: 1px solid #f4f4f4;
}

.navbar-nav>.messages-menu>.dropdown-menu li .menu>li>a>h4 {
    padding: 0;
    margin: 0 0 0 45px;
    color: #333;
    font-size: 15px;
    position: relative;
}

.navbar-nav>.messages-menu>.dropdown-menu li .menu>li>a>p {
    margin: 0 0 0 45px;
    font-size: 12px;
    color: #888888;
}

.footer-ul a {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 12px;
    background-color: #fff;
    padding: 7px 10px;
    border-bottom: 1px solid #eeeeee;
    color: #333;
    display: block;
}

.dropdown-menu-over .menu {
    max-height: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: hidden;
}

.navbar-nav>.notifications-menu>.dropdown-menu li .menu>li>a {
    color: #444444;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
}

.navbar-nav>.notifications-menu>.dropdown-menu li .menu>li>a>.glyphicon,
.navbar-nav>.notifications-menu>.dropdown-menu li .menu>li>a>.fa,
.navbar-nav>.notifications-menu>.dropdown-menu li .menu>li>a>.ion {
    width: 20px;
}

a.navbar-brand {
    width: 250px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/***    left menu ****/

/***********************  TOP Bar ********************/
.sidebar {
    position: fixed;
    top: 74px;
    left: 0px;
    width: 280px;
    height: calc(100vh - 74px);
    background: #ffffff !important;
    transition: all 0.5s ease-in-out;
    overflow: auto;
    box-shadow: 0px 4px 4px 0px #2122263D;
    box-shadow: 0px 0px 4px 0px #2122261F;
    //styleName: Body/Large;

    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #555555;

    &:hover {
        &::-webkit-scrollbar {
            width: 5px !important;
        }
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        border-radius: 10px !important;
        background-color: transparent !important;
    }

    &::-webkit-scrollbar {
        width: 0px !important;
        height: 11px !important;
        background-color: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        -webkit-box-shadow: none !important;
        background-color: #6f708b !important;
    }
}

.bg-default {
    background: white;
}

.sidebar ul {
    font-size: 14px;
    list-style: none;
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.sidebar li a,
.sidebar li a.collapsed.active {
    display: flex;
    align-items: center;
    padding: 14px 15px 13px 17px !important;
    // color: #ccd0d3;
    // border-left: 0px solid #dedede;
    text-decoration: none;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    height: 48px;
    box-sizing: border-box;
    color: #555555;
}

.sidebar li a.active {
    background-color: #ebf2fe;
    border-radius: 4px;
    color: #555555 !important;
}

.sidebar li a:hover {
    background-color: #ebf2fe;
    border-radius: 4px;
    color: #555555 !important;

    // svg {
    //     path {
    //         fill: #ffffff;
    //         stroke: #ffffff;

    //     }

    //     circle {
    //         stroke: #ffffff;
    //     }

    //     .svg_api {
    //         fill: #ffffff;
    //         stroke: #ffffff;
    //     }

    //     .svg_domain {
    //         fill: transparent;
    //         stroke: #ffffff;
    //     }

    //     .svg_theme_path {
    //         fill: transparent;
    //     }

    //     .svg_task {
    //         fill: transparent;
    //     }
    // }
}

.sidebar li a.active-tab,
.sidebar li a.active-tab:hover {
    background-color: #ebf2fe;
    border-radius: 4px;
    color: #555555 !important;

    // color: #38b6a0;

    // svg {
    //     path {
    //         fill: #fff;
    //         stroke: #fff;
    //     }

    //     circle {
    //         stroke: #fff;
    //     }

    //     .svg_api {
    //         fill: #fff;
    //         stroke: #fff;
    //     }

    //     .svg_domain {
    //         fill: transparent;
    //         stroke: #fff;
    //     }

    //     .svg_theme_path {
    //         fill: transparent;
    //     }

    //     .svg_task {
    //         fill: transparent;
    //     }
    // }
}

.sidebar li a i {
    padding-right: 5px;
}

.sidebar ul li .sub-menu li a {
    position: relative;
}

.sidebar ul li .sub-menu li a:before {
    font-family: FontAwesome;
    content: "\f105";
    display: inline-block;
    padding-left: 0px;
    padding-right: 10px;
    vertical-align: middle;
}

.sidebar ul li .sub-menu li a:hover:after {
    content: "";
    position: absolute;
    left: -5px;
    top: 0;
    width: 5px;
    // background-color: #111;
    height: 100%;
}

.sidebar ul li .sub-menu li a:hover {
    // background-color: #222;
    // padding-left: 20px;
    // transition: all 0.5s ease-in-out;
    background-color: #ebf2fe;
    border-radius: 4px;
    color: #555555 !important;
}

.sub-menu {
    border-left: 5px solid #dedede;
}

.sidebar li a .nav-label,
.sidebar li a i svg path,
.sidebar li a i svg circle,
.sidebar li a .nav-label+span {
    transition: all 0.5s ease-in-out;
    font-weight: 400;
    color: #555555;
}

.sidebar.fliph li a .nav-label,
.sidebar.fliph li a .nav-label+span {
    display: none;
    transition: all 0.5s ease-in-out;
}

.sidebar.fliph {
    width: 66px;
    transition: all 0.5s ease-in-out;
}

.sidebar.fliph li {
    position: relative;
}

.sidebar.fliph .sub-menu {
    position: absolute;
    left: 39px;
    top: 0;
    background-color: #222;
    width: 150px;
    z-index: 100;
}

.user-panel {
    clear: left;
    display: block;
    float: left;
}

.user-panel>.image>img {
    width: 100%;
    max-width: 45px;
    height: auto;
}

.user-panel>.info,
.user-panel>.info>a {
    color: #fff;
}

.user-panel>.info>p {
    font-weight: 600;
    margin-bottom: 9px;
}

.user-panel {
    clear: left;
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 15px;
    padding: 25px 15px;
    border-bottom: 1px solid;
}

.user-panel>.info {
    padding: 5px 5px 5px 15px;
    line-height: 1;
    position: absolute;
    left: 55px;
}

.fliph .user-panel {
    display: none;
}

.navbar-header {
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    width: 280px;
    height: 60px;
    // background: $theme-color-text-dark;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    transition: all 0.5s ease-in-out;
    // box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);

    &.collapsed {
        width: 66px;
    }
}

.navbar {
    background: #ffffff !important;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
}

#sidebar>.navbar-dark .navbar-nav .nav-link {
    color: $theme-color-dark-gray !important;

    &:hover {
        // color: $theme-color-text-dark !important;
        background-color: #ebf2fe;
        border-radius: 4px;
        color: #555555 !important;
    }
}

// sidebar and header SCSS end

.profile-img {
    width: 24px;
    height: 24px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}

.width100 {
    width: 100%;
}

// dataTable SCSS start
#dataTable_length,
#dataTable_filter,
#dataTable_paginate,
#dataTable_info,
tfoot {
    display: none;
}

#anotherDataTable_length,
#anotherDataTable_filter,
#anotherDataTable_paginate,
#anotherDataTable_info,
tfoot {
    display: none;
}

table {
    background: #ffffff;
    border: 1px solid $border-color !important;
    // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border-collapse: collapse !important;

    td,
    th {
        border: none !important;
    }

    thead {
        font-size: 14px;
        background: #ffffff;
        height: 48px;
        border-bottom: 1px solid $border-color;
        color: $thheader-font-color;
        font-weight: 700;
        // box-shadow: 0px 3px 4px rgba(214, 214, 216, 1);

        td,
        th {
            padding: 15px 18px !important;
            // padding: 15px 18px !important;
            white-space: nowrap;
        }
    }

    tbody {

        color: $tbody-font-color;

        td,
        th {
            // padding: 10px 18px !important;
            padding: 2px 18px !important;
        }

        .action_icon {
            display: inline-flex;
            // width: 29px;
            // height: 29px;
            cursor: pointer;
            margin-left: 0px !important;
            margin-right: 0.50rem !important;
            fill: #555555;
        }

        tr {
            // background: rgba(235, 247, 245, 0.15) !important;
            // border-bottom: 1px solid #f0f0f5 !important;

            &:hover {
                // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

                // .actions_wrapper {
                //   display: table-cell;
                // }

                // .modifi_dates_wrapper {
                //   display: none;
                // }
            }

            &:last-child {
                border-bottom: none !important;
            }

            td {
                min-height: 56px;
                font-size: 14px;
                vertical-align: middle !important;
                font-weight: 400;

                &.actions_wrapper {
                    // display: none;
                    white-space: nowrap;
                    /*text-align: center;*/
                }
            }
        }
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    &.current {
        width: 32px;
        height: 32px;
        padding: 3px !important;
        background: #ffffff !important;
        border: 1px solid $theme-color !important;
        box-sizing: border-box !important;
        border-radius: 4px !important;
        color: $theme-color !important;

        &:hover {
            color: $theme-color !important;
        }
    }
}

// pagination start

.pagination {
    .page-item {
        &.active .page-link {
            background: $background-primary;
            border-color: $theme-color;
            color: #ffffff !important;
        }

        .page-link {
            width: 35px;
            height: 35px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            border-color: #acadc1;
            color: $theme-color-dark-gray;
            border: none;

            &:hover {
                background-color: rgba($background-primary, 0.08);
            }

            &:active {
                background-color: $background-primary;

                svg {
                    path {
                        fill: white !important;
                    }
                }
            }

            &:focus-visible {
                border: 2px solid $background-primary;
                box-shadow: none;
            }

            &:focus {
                border: none;
                box-shadow: none;
            }
        }
    }
}

// pagination end

.disabled {
    svg {
        opacity: 0.5;
    }
}

// dataTable SCSS end

.dashboard-card-wrapper {
    .dashboard-card {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.08);
        box-sizing: border-box;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        border-radius: 4px;

        .dashboard-card-footer {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            background: none;
            border: none;
            position: absolute;
            bottom: 0px;
            right: 0px;

            .dashboard-card-arrow {
                width: 40px;
                height: 40px;
                padding: 10px 15px;
                background: #f9f8fc;
                border-radius: 1000px 0px 0px 0px;
            }
        }
    }
}

.org-card-wrapper {
    .org-card {
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            .org-action {
                #org_tick {
                    circle {
                        fill: $theme-color;
                    }

                    path {
                        fill: #ffffff;
                    }
                }
            }
        }

        .org-card-header {
            border-radius: 4px 4px 0px 0px;
            border-top: 4px solid #8e8fa6;

            &.border-primary {
                border-color: $primaryBlue-color ;
            }

            .org-action {
                &:focus {
                    outline: none;
                }

                svg {
                    cursor: pointer;
                }
            }
        }

        .org-card-body {
            background: rgba(248, 248, 251, 1);

            &.bg-primary {
                background: rgba(235, 247, 245, 0.5) !important;
            }

            &.bg-warning {
                background: rgba(254, 245, 237, 0.5) !important;
            }

            .list-group-item {
                font-size: 14px;
                background: none;
            }
        }

        .org-card-footer {
            background: rgba(248, 248, 251, 1);

            &.bg-primary {
                background: rgba(235, 247, 245, 0.5) !important;
            }

            &.bg-warning {
                background: rgba(254, 245, 237, 0.5) !important;
            }
        }
    }
}

.filter-dropdown {
    display: inline-flex;
    position: relative;

    &:hover,
    &.show {
        svg {
            path {
                fill: rgba(0, 91, 240, 0.08);
            }

            .vl,
            circle {
                fill: rgba(0, 91, 240);
            }
        }
    }

    .btn.focus,
    .btn:focus {
        box-shadow: none !important;
        outline: none !important;
        -webkit-box-shadow: none;
    }

    .dropdown-menu {
        position: absolute;
        top: 45px !important;
        left: 50% !important;
        transform: translate(-50%, 0%) !important;
        width: 250px;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        z-index: 4;

        &.show {
            display: inline-block !important;
        }

        &.dropdown-menu-app {
            width: 300px;
            padding-bottom: 0 !important;
        }

        label {
            font-size: 14px;
        }

        .filter-footer {
            height: 50px;
            background: #ffffff;
            box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.08);
            border-radius: 0px 0px 3px 3px;
        }
    }
}

.nav-tabs {
    border: none !important;
    .nav-item {
        .custom-nav-link {
            text-transform: uppercase;
            font-size: 12px !important;
            font-weight: 400 !important;
            letter-spacing: 1px !important;
            &.active {
                font-weight: 700 !important;
            }
        }
    }
}

.tab-container {
    background: #ffffff;
    border-radius: 4px;
}

.custom-nav-link {
    background: #dfe0e7;
    color: #555555;
    padding: 0.3rem 1rem !important;
    margin: 4px 10px 0px 0px;
    height: 40px !important;
    display: flex !important;
    align-items: center;

    // &.active {
    //     padding: 0.5rem 1rem !important;
    //     margin: 0px 10px 0px 0px;
    //     border: none !important;
    // }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-item {
    // margin-right: 0px!important;
}

.nav-tabs .nav-link {
    border: none !important;
    border-bottom: 3px solid transparent !important;
}

.custom-nav-link:hover {
    color: #555555 !important;
}

.custom-nav-link:hover {
    color: #555555 !important;
}

.custom-nav-link.focus {
    border: 2px solid #005FCC !important;
}

.custom-nav-link.active {
    border: none !important;
    border-bottom: 3px solid #005BF0 !important;
}

#currentPasswordModal .form-group div.w-xl-50 {
    width: 380px !important;
}

// modal SCSS start
.modal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                .close {
                    font-size: 2rem;
                    font-weight: normal;
                }
            }
        }
    }
}

// modal SCSS end
$breadcrumb-divider: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjc3MDQzIDBMMC40MDAzOTEgMS40MUw0Ljg1MDU5IDZMMC40MDAzOTEgMTAuNTlMMS43NzA0MyAxMkw3LjYwMDM5IDZMMS43NzA0MyAwWiIgZmlsbD0iI0FDQURDMSIvPgo8L3N2Zz4K);

.breadcrumb {
    .breadcrumb-item {
        a {
            color: #acadc1;

            &:hover {
                text-decoration: none;
                color: $theme-color;
            }
        }

        &.active a {
            // color: $theme-color;
            color: #26273b;

        }

        // &::before {
        //     content: $breadcrumb-divider !important;
        // }

        &:nth-child(2) {
            &::before {
                margin: 0rem 0.5rem;
            }
        }
    }
}

.container-fluid {
    background: $theme-color-light-gray;
    // z-index: -1;
}

.ngxclipboard {
    input {
        background-color: white !important;
        border: 1px solid transparent;
        border-right: 0px;
        box-sizing: border-box;
        border-radius: 3px 0px 0px 3px;
        color: #9476c9;
    }

    &:hover {
        button {
            display: inline-block;
        }
    }

    button {
        display: none;
        border-radius: 0px 3px 3px 0px;
    }

    &:hover {
        input {
            background-color: rgba(0, 91, 240, 0.08) !important;
            border: 1px solid $primaryBlue-color !important;
            color: $primaryBlue-color !important;
            ;
        }
    }
}

.btn-purple {
    color: #ffffff !important;
    background: $theme-color-purple !important;
    border-color: $theme-color-purple !important;

    &:hover {
        background: #7a62ab !important;
    }

    &:focus {
        background: #695696 !important;
        box-shadow: none !important;
    }

    &:disabled,
    &.disabled {
        background: #ffffff !important;
        color: #cfd0dd !important;
        border-color: #cfd0dd !important;
    }
}

.alert {
    &.alert-secondary {
        line-height: 30px;
    }
}

.custom-control {
    .custom-control-input {
        &:checked~.custom-control-label::before {
            border-color: $theme-color;
            background: #ffffff;
        }

        &:checked~.custom-control-label::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2338B6A0'/%3e%3c/svg%3e") !important;
        }
    }
}

.box-shadow-t1 {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.border {
    &.bw-1 {
        border-width: 1px;
    }

    &.bw-2 {
        border-width: 2px;
    }

    &.dashed {
        border-style: dashed !important;
    }
}

.reco-size {
    color: #8e8fa6;
}

/* The switch checkbox toggle start */
.switch {
    position: relative;
    display: inline-block;
    width: 40px !important;
    min-width: 40px !important;
    height: 21px;
    float: right;

    /* Hide default HTML checkbox */
    input {
        display: none;

        &.primary:checked+.slider {
            background-color: #005BF0;
        }

        &:focus+.slider {
            box-shadow: 0 0 1px #005BF0;
        }

        &:checked+.slider:before {
            -webkit-transform: translateX(19px);
            -ms-transform: translateX(19px);
            transform: translateX(19px);
        }
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #BBBBBB;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
        }

        /* Rounded sliders */
        &.round {
            border-radius: 34px;
        }

        &.round:before {
            border-radius: 50%;
        }
    }
}

/* The switch checkbox toggle end */

.cursor {
    &.cursor-default {
        cursor: default !important;

        svg {
            cursor: default !important;
        }
    }

    &.pointer {
        cursor: pointer !important;
    }
}

.was-validated {

    select,
    textarea {
        &.form-control {
            &:valid {
                background-image: none !important;
            }
        }
    }
}

.bg-checkbox {
    background: #f5f5f5;
}

.w-85 {
    width: 85% !important;
}

.outline-0 {
    outline: none;
}

.bg-gray {
    background: #f0f0f5;
}

.tag-input {
    .tag-ripple {
        border-radius: 12px !important;
        max-height: 24px !important;
        background: #F5F5F5 !important;
        color: #ffffff !important;
        padding: 0px 4px 0px 8px;
    }

    .ng2-tag-input {
        background: #FFFFFF;
        border: 1px solid #707070 !important;
        border-radius: 4px;
        // box-shadow: 0px 2px 3px 1px rgba(85, 85, 85, 0.2) inset;
        padding: 4px 4px !important;
    }

    .tag-wrapper {
        .tag__text {
            color: #555555;
            font-size: 13px !important;
            font-weight: 700;
        }

        delete-icon {
            svg {
                path {
                    fill: #555555;
                }
            }
        }
    }
}

.footer {
    // position: fixed;
    right: 0px;
    left: 0px;
    background: #ffffff;
    color: #8e8fa6;
    border-top: 1px solid #dfe0e7;
    transition: all 0.5s ease-in-out;
    padding: 0.8rem;
    z-index: 999;
    position: absolute;
    left: 0px;
}

.accordion {
    background: #ffffff;
    border: 1px solid #dfe0e7;
    box-sizing: border-box;
}

.toast-container {
    top: 30px !important;
}

pre {
    white-space: break-spaces;
    word-break: break-all;
}

.toggle-view {
    transition: all 0.5s ease-in-out;

    &.collapsed-view {
        // margin-left: 66px;
        // position: relative;
        margin-left: 66px;
        // width: calc(100% - 66px);
    }

    &.expanded-view {
        // margin-left: 280px;
        // position: relative;
        // width: calc(100% - 280px);
        // left: 280px;
        margin-left: 280px;
    }
}


.toggle-view-fixedBar {
    transition: all 0.5s ease-in-out;

    &.collapsed-view {
        margin-left: 66px;
    }

    &.expanded-view {
        margin-left: 280px;
    }
}





// .footer.collapsed-view {
//     transition: all 0.5s ease-in-out;
//     margin-left: 66px;
// }       

.content-container {
    margin-top: 60px;
}

.dropdown-list {
    // position: relative !important;
    z-index: 1000 !important;
}

.toast-container .toast {
    color: white !important;
    background-color: black !important;
    width: 381px !important;
    // height: 121px!important;
    padding: 16px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.24) !important;
    box-shadow: 0px 0px 10px 0px rgba(33, 34, 38, 0.12) !important;

}

.toast-success {
    color: white !important;
    background-color: black !important;
    width: 381px !important;
    // height: 121px!important;
    padding: 16px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
    background-image: url("/assets/images/success-tick.png") !important;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.24) !important;
    box-shadow: 0px 0px 10px 0px rgba(33, 34, 38, 0.12) !important;
}

.toast-error {
    color: white !important;
    background-color: black !important;
    width: 381px !important;
    // height: 121px!important;
    padding: 16px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
    background-image: url("/assets/images/error-circle.png") !important;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.24) !important;
    box-shadow: 0px 0px 10px 0px rgba(33, 34, 38, 0.12) !important;
}

.tooltip {
    &.bs-tooltip-top {
        .arrow {
            &:before {
                border-top-color: #000000 !important;
            }
        }
    }

    &.bs-tooltip-bottom {
        .arrow {
            &:before {
                border-bottom-color: #000000 !important;
            }
        }
    }

    &.bs-tooltip-left {
        .arrow {
            &:before {
                border-left-color: #000000 !important;
            }
        }
    }

    &.bs-tooltip-right {
        .arrow {
            &:before {
                border-right-color: #000000 !important;
            }
        }
    }

    .tooltip-inner {
        text-align: left !important;
        color: #E6E6E6 !important;
        font-size: 12px !important;
        line-height: 18px;
        background-color: #000000 !important;
    }

    opacity: 1 !important;
}

router-outlet+* {
    display: block;
    height: 100%;
    width: 100%;
}

/*custom media queries*/

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
    .w-xs-100 {
        width: 100% !important;
    }

    .w-xs-75 {
        width: 75% !important;
    }

    .w-xs-50 {
        width: 50% !important;
    }

    .w-xs-25 {
        width: 25% !important;
    }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
    .w-sm-100 {
        width: 100% !important;
    }

    .w-sm-75 {
        width: 75% !important;
    }

    .w-sm-50 {
        width: 50% !important;
    }

    .w-sm-25 {
        width: 25% !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .w-md-100 {
        width: 100% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-25 {
        width: 25% !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .w-lg-100 {
        width: 100% !important;
    }

    .w-lg-75 {
        width: 75% !important;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-25 {
        width: 25% !important;
    }

    .container-fluid {
        min-width: 1100px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .w-xl-100 {
        width: 100% !important;
    }

    .w-xl-75 {
        width: 75% !important;
    }

    .w-xl-50 {
        width: 50% !important;
    }

    .w-xl-25 {
        width: 25% !important;
    }
}

/*custom media queries*/

/*custom checkbox */
.custom-check {
    display: block;
    position: relative;
    padding-left: 18px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // margin-top: -5px;
    height: 24px;
    width: 24px;
}

/* Hide the browser's default checkbox */
.custom-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #8E8FA6 !important;
}

/* On mouse-over, add a grey background color */
.custom-check:hover input~.checkmark {
    background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.custom-check input:checked~.checkmark {
    border: 1px solid #005BF0;
    background-color: #005BF0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-check input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-check .checkmark:after {
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(90deg);
    transform: rotate(45deg);

    width: 8px;
    height: 15px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    left: 7px;
    top: 1px;
}

/*custom checkbox */

.custom-border-color {
    border-color: #ACADC1 !important;
}

.m-h-560px {
    max-height: 560px;
}

.m-h-420px {
    max-height: 420px;
}

.min-h-420px {
    min-height: 420px
}

.h-350px {
    height: 350px;
}

.h-45px {
    height: 45px;
}

.w-97 {
    width: 97%;
}

.min-h-331px {
    min-height: 331px;
}

.max-h-331px {
    max-height: 331px;
}

.tl-fixed {
    table-layout: fixed;

}

.break-w {
    word-wrap: break-word;
    // word-break: break-all;
}

.resize-none {
    resize: none;
}

.fs-small {
    font-size: small;
}

.btn-choose-img {
    width: 133px;
    color: blue;
    border: 1px solid blue;
}

.p-none {
    padding: none;
}

.table-avtar {
    //   height: 35px;
    //   width: 35px;
    //   margin: 6px 10px;
    //   border-radius: 50%;
    height: auto;
    max-height: 40px !important;
    width: 40px;
    clip-path: circle(20px at center);
    // min-width: 40px;
    /* margin: 6px 10px; */
    // border-radius: 50%;
}

.mt-5pc {
    margin-top: 5%;
}

.mt-20pc {
    margin-top: 20%;
}

.mt-60pc {
    margin-top: 60%;
}

.mr-100px {
    margin-right: 100px;
}

.w-50px {
    width: 50px;
}

.form-control {
    &:focus {
        box-shadow: none !important;
    }
}

.edit-folder {
    top: -5px
}

.ls-none {
    list-style: none;
}

.lh {
    line-height: 2.2;
}

.bg-white-smoke {
    background-color: #F8F8F8;
}

.pt-1px {
    top: 1px;
}

.pt-3px {
    top: 3px;
}

.daterangepicker {
    left: 700px !important;
    width: fit-content !important;
}

.daterangepicker .drp-calendar {
    max-width: 378px !important;
}

button,
input,
optgroup,
select,
.input-group>.input-group-append>.input-group-text {
    height: 40px !important;
}

.btn {
    line-height: 1 !important;
    min-width: 120px;

    &.btn-sm {
        height: 40px !important;
    }
}

.form-control,
.input-group-text {
    // border: 1px solid #707070 !important;
    // box-shadow: 0px 1px 1px 1px #55555533 inset;
    color: #26273B !important;
}

select.form-control {
    font-size: 16px;
    border: 1px solid rgba(187, 187, 187, 1) !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #F5F5F5 100%) !important;
    color: #000000 !important;

    &:disabled {
        color: rgba(112, 112, 112, 1) !important;
        background: #fff !important;
    }
}

.h-50px {
    height: 50px !important;
}

.btn-link {
    color: $theme-color !important;
    font-weight: 600 !important;

    &:hover {
        text-decoration: none !important;
    }
}

.label-asterisk {
    &:after {
        content: "*";
        color: #F1736A;
        margin: 0px 0 0 5px;
    }
}

.alert-secondary {
    color: #26273B !important;
    background-color: #F4F1F9 !important;
    border-color: rgba(148, 118, 201, 0.2) !important;
}

.outline-none {
    outline: none !important;
    box-shadow: none !important;
}

.outline-none:focus {
    outline: none !important;
    box-shadow: none !important;
}

.close:focus {
    outline: none !important;
}

@media (max-width:768px) {
    .tl-fixed {
        table-layout: auto;
    }

    .collapsed-view {
        width: 100% !important;

    }

    .expanded-view {
        width: 100% !important;
    }

}



// .btn {
//     font-size: 15px!important;
//     min-width: 120px;
// }

.btn.btn-purple,
.btn#sidebarToggle {
    min-width: auto;
}

.ngxclipboard input {
    font-size: 14px !important;
}

.minWidth-43 {
    min-width: 43px;
}

.ngxclipboard {
    border: 1px solid transparent !important;
    border-radius: 4px;

    input {
        border: 1px solid transparent !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

input,
textarea {
    font-size: 14px !important;
}

.badge {
    background-color: $chip-bg-color;
    font-weight: inherit;
    font-size: inherit;
    margin-right: 10px;
    color: $tbody-font-color;
    font-size: 12px !important;
    font-weight: 700;
    height: 24px;
    min-width: 84px;
    display: flex;
    align-items: center;
    padding: 0px 4px 0px 8px;

    // position: relative;
    &:hover {
        cursor: pointer;
    }

    .badge-text {
        min-width: 70%;

        text-align: left;
    }

    // svg-icon {
    //     position: absolute;
    //     right: 8px;
    // }
}

.width-30-percent {
    width: 30% !important;

}

.width-250 {
    width: 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
}


.width-300 {
    width: 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
}

.width-335 {
    width: 335px !important;
    max-width: 335px !important;
    min-width: 335px !important;
}

.textEllipsis {
    width: 75%;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-segment-label,
.taskFolderTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block !important;
    max-width: 75%;
}

.taskTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block !important;
    max-width: 100%;
}

// .sorting_disabled {
//     font-weight: 600!important;
// }

.height20px {
    height: 20px;
}

.height50px {
    height: 50px;
}


.height12px {
    height: 12px;
}

@media (min-width:3840px) {
    .sidebar ul {
        font-size: 16px;
    }

    .sidebar li a,
    .sidebar li a.collapsed.active {
        height: 60px;
    }

    .btn {
        font-size: 16px !important;
    }

    input,
    select {
        font-size: 14px !important;
    }
}

.margin-auto {
    margin: auto;
}

* {
    outline: none !important;
}

*:focus {
    outline: none !important;
}





table {
    background-color: #fff !important;
}

table tbody tr:nth-child(odd) {
    // background-color: transparent!important;
}

table tbody tr:nth-child(even) {
    // background-color: rgba(235, 247, 245, 0.15)!important;
}

table tbody tr#labels:nth-child(even) {
    // background-color: rgba(235, 247, 245, 0.15)!important;
    // background-color: #eeeeee57!important;
}


.calendar-table table thead,
.calendar-table table thead tr {
    box-shadow: none;
}



table.dataTable thead th {
    vertical-align: middle;
}

.minWidth-120 {
    min-width: 120px;
}

.minWidth-160 {
    min-width: 160px;
}


.dropdown-btn span:last-child {
    // float: right !important;
    padding-right: 4px;
    // position: absolute;
    // right: 10px;
}

.textAlignCenter {
    text-align: center;
}

.tooltip {
    z-index: 1;
}

.infoIcon svg:not(:root) {
    width: 15px !important;
    height: 15px !important;
    margin-top: -3px !important;
}

.padding50 {
    padding-bottom: 50px;
}

.wrap {
    word-wrap: break-word;
}

.footer-logo-binary {
    position: absolute;
    bottom: 12px;
    right: 8px;
}

.borderR {
    border-right: 1px solid grey;
}

.borderL {
    border-left: 1px solid grey;

}

.progress {
    width: 192px;
    height: 4px !important;
}

.progress-bar {
    background-color: #005BF0 !important;
    border-radius: 4px !important;

}

.tooltip-width {
    width: 280px !important;

    .tooltip-inner {
        max-width: 720px !important;
    }

    //    height: auto;
}

.move-left-16 {
    left: 16px !important;
}


.component-section {
    background: white;
    border-radius: 4px;
    // border: 1px solid #cfd0dd;
    margin-top: 30px;
}

.sectionHeader {
    // background-color: #cfd0dd;
    // height: 22px;
    // padding: 8px 15px;
    // box-sizing: content-box;
    // border-radius: 8px 8px 0px 0px;
    font-size: 20px;
    line-height: 1;
    font-weight: 900;
    color: $section-title-color;
}

.refresh-section {
    float: right;
    margin-top: 1px;
    cursor: pointer;
}

.sectionBody {
    padding: 16px;
    // display: inline-block;
    display: flow-root;
    // box-shadow: 0px 2px 2px 0px rgba(33, 34, 38, 0.24);
    // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12);

}

.marginTop--10 {
    margin-top: -10px;
}

.marginTop-30 {
    margin-top: 30px;
}

.data-wrapper {
    display: inline-flex;
    height: 100%;
    align-items: center;
}

.dataTables_wrapper {
    // overflow-x: auto;
}

.overflow-x-table {
    overflow-x: auto;
}

.overflow-x-table table {
    width: 110% !important;
}

tbody tr#labels td:first-child {
    background-color: transparent !important;
}

tr#labels td .card {
    background-color: transparent !important;
}

.supportLink {
    color: $primaryBlue-color !important;
    text-decoration: underline !important;
    font-size: 14px;
    font-weight: 600;
}

.section-header {
    display: flex;
    align-items: start;
    height: 40px;
    margin-bottom: 20px;

    .section-title {
        text-align: left;
        font-size: 20px;
        font-weight: 900;
        color: $section-title-color;
    }
}

.table-container {
    border-radius: 0.5%;
    border-radius: 4px;
}

.displayFlex {
    display: flex;
}

.alignItemsCenter {
    align-items: center;
}

.primaryFont-color {
    color: $primaryBlue-color
}

.fontWeight-700 {
    font-weight: 700;
}

.fontWeight-900 {
    font-weight: 900;
}

table tr {
    min-height: 56px;
    height: 56px;
}

.list-sidebar>li {
    font-size: 16px;
}

.list-sidebar>.submenu-guides>li {
    font-size: 14px !important;
}

.cls-2 {
    fill: #555555 !important
}

.cls-1 {
    fill: rgb(255, 255, 255) !important
}

.switch-disabled {
    input {
        display: none;

        &.primary:checked+.slider {
            background-color: #BDD6FF;
            ;
            cursor: default !important;
        }

        &.primary:not(:checked)+.slider {
            cursor: default !important;
            background-color: #BBBBBB;
        }
    }
}

#tableCust thead {
    border: none !important;
}

.displayBlock {
    display: block;
}

.tag-wrapper {
    line-height: 2 !important;

    delete-icon {
        margin-top: -5px !important;

        svg path {
            fill: #555555 !important;
        }
    }
}

.font-light-color {
    color: $tbody-font-color;
}

tag {
    background-color: #F5F5F5 !important;
    font-weight: inherit !important;
    font-size: inherit !important;
    margin-right: 10px !important;
    color: #555555;
    font-size: 12px !important;
    font-weight: 700 !important;
    height: 24px !important;
    min-width: 84px;
    display: flex !important;
    align-items: center !important;
    padding: 0px 4px 0px 8px !important;
}

.input-radio-custom {
    width: 24px !important;
    height: 24px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #383838;
    border-radius: 50%;
    cursor: pointer;
    outline: 0;
    padding: 4px !important;
    background-clip: content-box;
}

.input-radio-custom:hover {
    border: 1px solid #BBBBBB;
    background-color: #005BF0;
    opacity: 0.5;
}

.input-radio-custom:checked {
    border: 2px solid #005BF0;
    background-color: #005BF0;
}

.input-radio-custom:focus {
    border: 1px solid #005BF0;
}

.ng2-tag-input .ng2-tags-container {
    align-items: center !important;
}

.ng2-tag-input__text-input {
    font-size: 16px !important;
    color: #707070;
}

select.form-control {
    box-shadow: none !important;
    appearance: none !important;
    /* Removes the default dropdown arrow */
    -webkit-appearance: none !important;
    /* For Safari */
    -moz-appearance: none !important;
    /* For Firefox */
    padding-right: 30px !important;
    /* Adds padding to the right side */

    background-color: white !important;
    border: 1px solid #bbbbbb !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F5F5F5 100%),
        url('data:image/svg+xml;utf8,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 10.332L17.59 9L13 13.3266L8.41 9L7 10.332L13 16L19 10.332Z" fill="%231C1D21"/></svg>') no-repeat right 6px top 8px / 26px !important;
}

input.form-control:focus,
textarea.form-control:focus {
    border: 2px solid #005FCC !important;
}

.search {
    background: url("../src/assets/svgs/search-icon-new.svg");

    background-position: 15px center;
    background-repeat: no-repeat;
    padding-right: 12rem !important;
}

.search::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input::placeholder {
    padding-left: 0px !important;
  }


  input[type="search"]{
    padding-left: 45px;
    border: 1px solid #bbbbbb;
    font-size: 16px!important;
    font-weight: 400!important;
    color: #707070!important;
    line-height: 24;
    padding-right: 10px !important;
  }
  .zIndex-3 > .main-drop-div {
    position: initial!important;
  }








/**
.multiselect-dropdown {
    .dropdown-btn {
        border: 1px solid #acadc1 !important;
        padding: 6px 8px !important;

        &:focus {
            border-color: $theme-color !important;
        }
    }

    .selected-item {
        background: $theme-color !important;
        border-color: $theme-color !important;
        margin: 2px 4px 2px 2px;
        font-size: 12px;
        font-weight: 400;
        padding: 2px 6px !important;
    }

    .dropdown-down {
        border-top: 8px solid #394042 !important;
        border-left: 8px solid transparent !important;
        border-right: 8px solid transparent !important;
    }

    .dropdown-up {
        border-bottom: 8px solid #394042 !important;
        border-left: 8px solid transparent !important;
        border-right: 8px solid transparent !important;
    }

    .multiselect-item-checkbox {
        input[type="checkbox"]+div {
            &:before {
                border-color: $theme-color !important;
                border-radius: 3px;
                border: 1px solid $theme-color !important;
            }
        }

        input[type="checkbox"]:checked+div {
            &:before {
                background: $theme-color !important;
                border: 1px solid $theme-color !important;
            }
        }

        input[type="checkbox"]:checked+div {
            &:after {
                left: 3px !important;
            }
        }
    }
}

.multiselect-dropdown .dropdown-btn {
    max-height: 35px !important;
}

.multiselect-dropdown .dropdown-btn {
    display: flex !important;
    align-items: center !important;
}

.multiselect-dropdown .dropdown-btn span:last-child {
    // float: right !important;
    padding-right: 4px;
    // position: absolute;
    // right: 10px;
}

.multiselect-dropdown .dropdown-btn span:last-child {
    // float: right !important;
    padding-right: 4px;
    // position: absolute;
    // right: 10px;
    display: flex;
    top: 14px;
}

.multiselect-dropdown .dropdown-btn .dropdown-down:last-child,
.multiselect-dropdown .dropdown-btn .dropdown-up:last-child,
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    position: relative;
    // right: 0px;
    top: 3px !important;
    padding: 0px;
}

**/

