/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  color: #383838 !important;
  background-color: #f5f5f5 !important;
  font-family: "Lato", "Open Sans", sans-serif !important;
}

input,
button,
select {
  outline: none;
}

/* You can add global styles to this file, and also import other style files */
.font10 {
  font-size: 10px;
}

.font13 {
  font-size: 13px;
}

.font14 {
  font-size: 14px;
}

.font15 {
  font-size: 15px;
}

.font16 {
  font-size: 16px;
}

.overflowHidden {
  overflow: hidden !important;
}

.overflowAuto {
  overflow: auto !important;
}

.overflowUnset {
  overflow: unset !important;
}

.btn-primary {
  background-color: #005BF0 !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863) !important;
  height: 40px !important;
  padding: 8px 16px 8px 16px;
  gap: 4px;
  border-radius: 4px 0px 0px 0px;
  border: none !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #ffffff !important;
}
.btn-primary:hover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863);
}
.btn-primary:focus {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
  border: 2px solid #005FCC;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863);
}
.btn-primary:active {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
  border: 2px solid #005FCC;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5019607843) inset;
}
.btn-primary:disabled, .btn-primary.disabled {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863);
  background-color: #F5F5F5 !important;
  border: 1px solid #E6E6E6 !important;
  color: #BBBBBB !important;
  pointer-events: none;
}

.btn-primary-borderless-medium {
  background-color: transparent !important;
  height: 32px !important;
  gap: 4px;
  border-radius: 4px;
  border: none !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  color: #005BF0 !important;
  padding: 4px 24px 4px 12px !important;
  gap: 4px !important;
}
.btn-primary-borderless-medium:focus {
  border: 2px solid #005FCC !important;
  padding: 2px 22px 2px 10px !important;
}
.btn-primary-borderless-medium:disabled, .btn-primary-borderless-medium.disabled {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863) !important;
  color: #BBBBBB !important;
  pointer-events: none;
}

.btn-primary-small {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
  font-weight: 700 !important;
  color: #ffffff !important;
  background-color: #005BF0 !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863) !important;
  height: 32px !important;
  padding: 4px 16px 4px 16px !important;
  gap: 4px;
  border: none !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  min-width: max-content !important;
}
.btn-primary-small:hover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863);
}
.btn-primary-small:focus {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0;
  border: 2px solid #005FCC;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863);
}
.btn-primary-small:active {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0;
  border: 2px solid #005FCC;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5019607843) inset;
}
.btn-primary-small:disabled, .btn-primary-small.disabled {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863);
  background-color: #F5F5F5 !important;
  border: 1px solid #E6E6E6 !important;
  color: #BBBBBB !important;
  pointer-events: none;
}

.btn-secondary {
  background: linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0784313725) !important;
  height: 40px !important;
  padding: 8px 16px 8px 16px !important;
  gap: 4px;
  border-radius: 4px !important;
  border: 1px solid #BBBBBB !important;
  color: #555555 !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}
.btn-secondary:hover {
  background: linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%) !important;
  border: 1px solid #BBBBBB !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0784313725) !important;
}
.btn-secondary:focus {
  background: linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
  border: 2px solid #005FCC !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0784313725) !important;
}
.btn-secondary:active {
  background: linear-gradient(0deg, #F5F5F5, #F5F5F5), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
  border: 1px solid #BBBBBB !important;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5019607843) inset !important;
}
.btn-secondary:disabled, .btn-secondary.disabled {
  background: #F5F5F5 !important;
  border: 1px solid #E6E6E6 !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0784313725) !important;
  color: #BBBBBB !important;
  pointer-events: none;
}

.btn-outline-secondary-small {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%), #f5f5f5 !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0784313725);
  height: 32px !important;
  padding: 8px 16px 8px 16px !important;
  gap: 4px;
  border-radius: 4px !important;
  border: 1px solid #BBBBBB !important;
  color: #555555 !important;
  font-size: 13px !important;
}
.btn-outline-secondary-small:hover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%), #f5f5f5;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863);
}
.btn-outline-secondary-small:focus {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%), #f5f5f5 !important;
  border: 2px solid #005FCC;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863);
}
.btn-outline-secondary-small:disabled, .btn-outline-secondary-small.disabled {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863);
  background: #F5F5F5;
  border: 1px solid #E6E6E6;
}

.abcde svg path {
  storke: green;
}

.btn-danger {
  color: #fff !important;
  font-size: 15px;
  border: none !important;
  background: linear-gradient(0deg, #DE350B, #DE350B), linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(33, 34, 38, 0.2) !important;
}
.btn-danger:hover {
  background: linear-gradient(0deg, #DE350B, #DE350B), linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(33, 34, 38, 0.2);
  border: none !important;
}
.btn-danger:focus {
  border: 2px solid #005FCC !important;
  box-shadow: 0px 1px 2px 0px rgba(33, 34, 38, 0.2) !important;
  background: linear-gradient(0deg, #DE350B, #DE350B), linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;
}
.btn-danger:active {
  background: linear-gradient(0deg, #DE350B, #DE350B), linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5019607843) inset;
}
.btn-danger:disabled, .btn-danger.disabled {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863) !important;
  background: #F5F5F5 !important;
  border: 1px solid #E6E6E6 !important;
}

.btn-outline-primary {
  color: #005BF0 !important;
  background: #ffffff !important;
  border-color: #005BF0 !important;
  height: 40px !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
  font-weight: 700;
  font-size: 15px;
}
.btn-outline-primary:hover {
  background: rgba(0, 91, 240, 0.08);
  border: 1px solid rgb(0, 91, 240);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
}
.btn-outline-primary:focus {
  border: 2px solid rgb(0, 95, 204);
  color: #005BF0 !important;
  background: #ffffff !important;
  border-color: #005BF0 !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  background: rgb(245, 245, 245);
  border: 1px solid rgb(230, 230, 230);
  color: rgb(187, 187, 187);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
}

h3 {
  color: #005BF0 !important;
}

.theme-color {
  color: #005BF0 !important;
}

a {
  cursor: pointer;
}

.marginTop-20 {
  margin-top: 20px;
}

.rowGap10 {
  row-gap: 10px;
}

.margin0padding0 {
  margin: 0px !important;
  padding: 0px !important;
  background: #f5f5f5;
}

.font13 {
  font-size: 13px;
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font15 {
  font-size: 15px;
}

.bg-login {
  background: #ebf7f5;
  padding-left: 0px;
}

.w-12 {
  width: 12%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.h-96 {
  height: 96%;
}

.footer-text {
  color: #acadc1;
}

.angular-google-recaptcha-container {
  width: 99%;
}

.bg-light-grey {
  background: #f5f5f5;
}

.text-color-dark {
  color: #000000 !important;
}

.text-color-dark-grey {
  color: #383838;
}

.otp-input {
  width: 40px !important;
  height: 40px !important;
  font-size: 24px !important;
  background: #ffffff;
  border-color: #acadc1 !important;
  outline: none;
}
.otp-input:not(:last-child) {
  margin-right: 24px !important;
}

.otp-input-focused {
  background: #ebf7f5;
  color: #005BF0;
  border-color: #005BF0 !important;
}

.error.bg {
  background: #fdf2f1 !important;
}
.error.color {
  color: #f1736a !important;
}
.error.color.dark {
  color: #b3463f !important;
}
.error.border {
  border-color: #f1736a !important;
}

/** ======================  base css ==============================**/
a:hover {
  text-decoration: none;
}

/** ======================  header ==============================**/
.header {
  background-color: #3c8dbc;
}

.header .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
  border-radius: 6px;
  top: 58px;
}

.header .user-image {
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -2px;
}

.header .navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.header .navbar-light .navbar-nav .nav-link:hover,
.header .navbar-light .navbar-nav .nav-link:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

.header .fa.fa-fw.fa-bars {
  color: #fff;
}

.header .navbar-light .navbar-nav .nav-link {
  color: #fff;
  padding: 10px 20px;
  position: relative;
}

.header li > a > .label {
  position: absolute;
  top: 9px;
  right: 7px;
  text-align: center;
  font-size: 9px;
  padding: 2px 3px;
  line-height: 0.9;
  background-color: #333;
  border-radius: 0.25em;
}

.header li > a:after {
  display: none;
}

.header-ul {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #ffffff;
  padding: 7px 10px;
  border-bottom: 1px solid #f4f4f4;
  color: #333;
  font-size: 14px;
}

.navbar-nav > .notifications-menu > .dropdown-menu,
.navbar-nav > .messages-menu > .dropdown-menu,
.navbar-nav > .tasks-menu > .dropdown-menu {
  width: 280px;
  padding: 0 0 0 0;
  margin: 0;
  top: 100%;
}

.navbar-nav > .messages-menu > .dropdown-menu li .menu > li > a > div > img {
  margin: auto 10px auto auto;
  width: 40px;
  height: 40px;
}

.navbar-nav > .messages-menu > .dropdown-menu li .menu > li > a,
.navbar-nav > .notifications-menu > .dropdown-menu li .menu > li > a {
  margin: 0;
  padding: 10px 10px;
  display: block;
  white-space: nowrap;
  border-bottom: 1px solid #f4f4f4;
}

.navbar-nav > .messages-menu > .dropdown-menu li .menu > li > a > h4 {
  padding: 0;
  margin: 0 0 0 45px;
  color: #333;
  font-size: 15px;
  position: relative;
}

.navbar-nav > .messages-menu > .dropdown-menu li .menu > li > a > p {
  margin: 0 0 0 45px;
  font-size: 12px;
  color: #888888;
}

.footer-ul a {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 12px;
  background-color: #fff;
  padding: 7px 10px;
  border-bottom: 1px solid #eeeeee;
  color: #333;
  display: block;
}

.dropdown-menu-over .menu {
  max-height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
}

.navbar-nav > .notifications-menu > .dropdown-menu li .menu > li > a {
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
}

.navbar-nav > .notifications-menu > .dropdown-menu li .menu > li > a > .glyphicon,
.navbar-nav > .notifications-menu > .dropdown-menu li .menu > li > a > .fa,
.navbar-nav > .notifications-menu > .dropdown-menu li .menu > li > a > .ion {
  width: 20px;
}

a.navbar-brand {
  width: 250px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/***    left menu ****/
/***********************  TOP Bar ********************/
.sidebar {
  position: fixed;
  top: 74px;
  left: 0px;
  width: 280px;
  height: calc(100vh - 74px);
  background: #ffffff !important;
  transition: all 0.5s ease-in-out;
  overflow: auto;
  box-shadow: 0px 4px 4px 0px rgba(33, 34, 38, 0.2392156863);
  box-shadow: 0px 0px 4px 0px rgba(33, 34, 38, 0.1215686275);
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #555555;
}
.sidebar:hover::-webkit-scrollbar {
  width: 5px !important;
}
.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  border-radius: 10px !important;
  background-color: transparent !important;
}
.sidebar::-webkit-scrollbar {
  width: 0px !important;
  height: 11px !important;
  background-color: transparent !important;
}
.sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: none !important;
  background-color: #6f708b !important;
}

.bg-default {
  background: white;
}

.sidebar ul {
  font-size: 14px;
  list-style: none;
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sidebar li a,
.sidebar li a.collapsed.active {
  display: flex;
  align-items: center;
  padding: 14px 15px 13px 17px !important;
  text-decoration: none;
  height: 48px;
  box-sizing: border-box;
  color: #555555;
}

.sidebar li a.active {
  background-color: #ebf2fe;
  border-radius: 4px;
  color: #555555 !important;
}

.sidebar li a:hover {
  background-color: #ebf2fe;
  border-radius: 4px;
  color: #555555 !important;
}

.sidebar li a.active-tab,
.sidebar li a.active-tab:hover {
  background-color: #ebf2fe;
  border-radius: 4px;
  color: #555555 !important;
}

.sidebar li a i {
  padding-right: 5px;
}

.sidebar ul li .sub-menu li a {
  position: relative;
}

.sidebar ul li .sub-menu li a:before {
  font-family: FontAwesome;
  content: "\f105";
  display: inline-block;
  padding-left: 0px;
  padding-right: 10px;
  vertical-align: middle;
}

.sidebar ul li .sub-menu li a:hover:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 0;
  width: 5px;
  height: 100%;
}

.sidebar ul li .sub-menu li a:hover {
  background-color: #ebf2fe;
  border-radius: 4px;
  color: #555555 !important;
}

.sub-menu {
  border-left: 5px solid #dedede;
}

.sidebar li a .nav-label,
.sidebar li a i svg path,
.sidebar li a i svg circle,
.sidebar li a .nav-label + span {
  transition: all 0.5s ease-in-out;
  font-weight: 400;
  color: #555555;
}

.sidebar.fliph li a .nav-label,
.sidebar.fliph li a .nav-label + span {
  display: none;
  transition: all 0.5s ease-in-out;
}

.sidebar.fliph {
  width: 66px;
  transition: all 0.5s ease-in-out;
}

.sidebar.fliph li {
  position: relative;
}

.sidebar.fliph .sub-menu {
  position: absolute;
  left: 39px;
  top: 0;
  background-color: #222;
  width: 150px;
  z-index: 100;
}

.user-panel {
  clear: left;
  display: block;
  float: left;
}

.user-panel > .image > img {
  width: 100%;
  max-width: 45px;
  height: auto;
}

.user-panel > .info,
.user-panel > .info > a {
  color: #fff;
}

.user-panel > .info > p {
  font-weight: 600;
  margin-bottom: 9px;
}

.user-panel {
  clear: left;
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 15px;
  padding: 25px 15px;
  border-bottom: 1px solid;
}

.user-panel > .info {
  padding: 5px 5px 5px 15px;
  line-height: 1;
  position: absolute;
  left: 55px;
}

.fliph .user-panel {
  display: none;
}

.navbar-header {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  width: 280px;
  height: 60px;
  transition: all 0.5s ease-in-out;
}
.navbar-header.collapsed {
  width: 66px;
}

.navbar {
  background: #ffffff !important;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
}

#sidebar > .navbar-dark .navbar-nav .nav-link {
  color: #383838 !important;
}
#sidebar > .navbar-dark .navbar-nav .nav-link:hover {
  background-color: #ebf2fe;
  border-radius: 4px;
  color: #555555 !important;
}

.profile-img {
  width: 24px;
  height: 24px;
}
.profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.width100 {
  width: 100%;
}

#dataTable_length,
#dataTable_filter,
#dataTable_paginate,
#dataTable_info,
tfoot {
  display: none;
}

#anotherDataTable_length,
#anotherDataTable_filter,
#anotherDataTable_paginate,
#anotherDataTable_info,
tfoot {
  display: none;
}

table {
  background: #ffffff;
  border: 1px solid #E6E6E6 !important;
  border-radius: 4px;
  border-collapse: collapse !important;
}
table td,
table th {
  border: none !important;
}
table thead {
  font-size: 14px;
  background: #ffffff;
  height: 48px;
  border-bottom: 1px solid #E6E6E6;
  color: #383838;
  font-weight: 700;
}
table thead td,
table thead th {
  padding: 15px 18px !important;
  white-space: nowrap;
}
table tbody {
  color: #555555;
}
table tbody td,
table tbody th {
  padding: 2px 18px !important;
}
table tbody .action_icon {
  display: inline-flex;
  cursor: pointer;
  margin-left: 0px !important;
  margin-right: 0.5rem !important;
  fill: #555555;
}
table tbody tr:last-child {
  border-bottom: none !important;
}
table tbody tr td {
  min-height: 56px;
  font-size: 14px;
  vertical-align: middle !important;
  font-weight: 400;
}
table tbody tr td.actions_wrapper {
  white-space: nowrap;
  /*text-align: center;*/
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  width: 32px;
  height: 32px;
  padding: 3px !important;
  background: #ffffff !important;
  border: 1px solid #005BF0 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  color: #005BF0 !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #005BF0 !important;
}

.pagination .page-item.active .page-link {
  background: #005BF0;
  border-color: #005BF0;
  color: #ffffff !important;
}
.pagination .page-item .page-link {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-color: #acadc1;
  color: #383838;
  border: none;
}
.pagination .page-item .page-link:hover {
  background-color: rgba(0, 91, 240, 0.08);
}
.pagination .page-item .page-link:active {
  background-color: #005BF0;
}
.pagination .page-item .page-link:active svg path {
  fill: white !important;
}
.pagination .page-item .page-link:focus-visible {
  border: 2px solid #005BF0;
  box-shadow: none;
}
.pagination .page-item .page-link:focus {
  border: none;
  box-shadow: none;
}

.disabled svg {
  opacity: 0.5;
}

.dashboard-card-wrapper .dashboard-card {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.dashboard-card-wrapper .dashboard-card .dashboard-card-footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background: none;
  border: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.dashboard-card-wrapper .dashboard-card .dashboard-card-footer .dashboard-card-arrow {
  width: 40px;
  height: 40px;
  padding: 10px 15px;
  background: #f9f8fc;
  border-radius: 1000px 0px 0px 0px;
}

.org-card-wrapper .org-card {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;
}
.org-card-wrapper .org-card:hover .org-action #org_tick circle {
  fill: #005BF0;
}
.org-card-wrapper .org-card:hover .org-action #org_tick path {
  fill: #ffffff;
}
.org-card-wrapper .org-card .org-card-header {
  border-radius: 4px 4px 0px 0px;
  border-top: 4px solid #8e8fa6;
}
.org-card-wrapper .org-card .org-card-header.border-primary {
  border-color: #005BF0;
}
.org-card-wrapper .org-card .org-card-header .org-action:focus {
  outline: none;
}
.org-card-wrapper .org-card .org-card-header .org-action svg {
  cursor: pointer;
}
.org-card-wrapper .org-card .org-card-body {
  background: rgb(248, 248, 251);
}
.org-card-wrapper .org-card .org-card-body.bg-primary {
  background: rgba(235, 247, 245, 0.5) !important;
}
.org-card-wrapper .org-card .org-card-body.bg-warning {
  background: rgba(254, 245, 237, 0.5) !important;
}
.org-card-wrapper .org-card .org-card-body .list-group-item {
  font-size: 14px;
  background: none;
}
.org-card-wrapper .org-card .org-card-footer {
  background: rgb(248, 248, 251);
}
.org-card-wrapper .org-card .org-card-footer.bg-primary {
  background: rgba(235, 247, 245, 0.5) !important;
}
.org-card-wrapper .org-card .org-card-footer.bg-warning {
  background: rgba(254, 245, 237, 0.5) !important;
}

.filter-dropdown {
  display: inline-flex;
  position: relative;
}
.filter-dropdown:hover svg path, .filter-dropdown.show svg path {
  fill: rgba(0, 91, 240, 0.08);
}
.filter-dropdown:hover svg .vl,
.filter-dropdown:hover svg circle, .filter-dropdown.show svg .vl,
.filter-dropdown.show svg circle {
  fill: rgb(0, 91, 240);
}
.filter-dropdown .btn.focus,
.filter-dropdown .btn:focus {
  box-shadow: none !important;
  outline: none !important;
  -webkit-box-shadow: none;
}
.filter-dropdown .dropdown-menu {
  position: absolute;
  top: 45px !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  width: 250px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  z-index: 4;
}
.filter-dropdown .dropdown-menu.show {
  display: inline-block !important;
}
.filter-dropdown .dropdown-menu.dropdown-menu-app {
  width: 300px;
  padding-bottom: 0 !important;
}
.filter-dropdown .dropdown-menu label {
  font-size: 14px;
}
.filter-dropdown .dropdown-menu .filter-footer {
  height: 50px;
  background: #ffffff;
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 3px 3px;
}

.nav-tabs {
  border: none !important;
}
.nav-tabs .nav-item .custom-nav-link {
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 1px !important;
}
.nav-tabs .nav-item .custom-nav-link.active {
  font-weight: 700 !important;
}

.tab-container {
  background: #ffffff;
  border-radius: 4px;
}

.custom-nav-link {
  background: #dfe0e7;
  color: #555555;
  padding: 0.3rem 1rem !important;
  margin: 4px 10px 0px 0px;
  height: 40px !important;
  display: flex !important;
  align-items: center;
}

.nav-tabs .nav-link {
  border: none !important;
  border-bottom: 3px solid transparent !important;
}

.custom-nav-link:hover {
  color: #555555 !important;
}

.custom-nav-link:hover {
  color: #555555 !important;
}

.custom-nav-link.focus {
  border: 2px solid #005FCC !important;
}

.custom-nav-link.active {
  border: none !important;
  border-bottom: 3px solid #005BF0 !important;
}

#currentPasswordModal .form-group div.w-xl-50 {
  width: 380px !important;
}

.modal .modal-dialog .modal-content .modal-header .close {
  font-size: 2rem;
  font-weight: normal;
}

.breadcrumb .breadcrumb-item a {
  color: #acadc1;
}
.breadcrumb .breadcrumb-item a:hover {
  text-decoration: none;
  color: #005BF0;
}
.breadcrumb .breadcrumb-item.active a {
  color: #26273b;
}
.breadcrumb .breadcrumb-item:nth-child(2)::before {
  margin: 0rem 0.5rem;
}

.container-fluid {
  background: #f5f5f5;
}

.ngxclipboard input {
  background-color: white !important;
  border: 1px solid transparent;
  border-right: 0px;
  box-sizing: border-box;
  border-radius: 3px 0px 0px 3px;
  color: #9476c9;
}
.ngxclipboard:hover button {
  display: inline-block;
}
.ngxclipboard button {
  display: none;
  border-radius: 0px 3px 3px 0px;
}
.ngxclipboard:hover input {
  background-color: rgba(0, 91, 240, 0.08) !important;
  border: 1px solid #005BF0 !important;
  color: #005BF0 !important;
}

.btn-purple {
  color: #ffffff !important;
  background: #9476c9 !important;
  border-color: #9476c9 !important;
}
.btn-purple:hover {
  background: #7a62ab !important;
}
.btn-purple:focus {
  background: #695696 !important;
  box-shadow: none !important;
}
.btn-purple:disabled, .btn-purple.disabled {
  background: #ffffff !important;
  color: #cfd0dd !important;
  border-color: #cfd0dd !important;
}

.alert.alert-secondary {
  line-height: 30px;
}

.custom-control .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #005BF0;
  background: #ffffff;
}
.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2338B6A0'/%3e%3c/svg%3e") !important;
}

.box-shadow-t1 {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.border.bw-1 {
  border-width: 1px;
}
.border.bw-2 {
  border-width: 2px;
}
.border.dashed {
  border-style: dashed !important;
}

.reco-size {
  color: #8e8fa6;
}

/* The switch checkbox toggle start */
.switch {
  position: relative;
  display: inline-block;
  width: 40px !important;
  min-width: 40px !important;
  height: 21px;
  float: right;
  /* Hide default HTML checkbox */
  /* The slider */
}
.switch input {
  display: none;
}
.switch input.primary:checked + .slider {
  background-color: #005BF0;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #005BF0;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #BBBBBB;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  /* Rounded sliders */
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}

/* The switch checkbox toggle end */
.cursor.cursor-default {
  cursor: default !important;
}
.cursor.cursor-default svg {
  cursor: default !important;
}
.cursor.pointer {
  cursor: pointer !important;
}

.was-validated select.form-control:valid,
.was-validated textarea.form-control:valid {
  background-image: none !important;
}

.bg-checkbox {
  background: #f5f5f5;
}

.w-85 {
  width: 85% !important;
}

.outline-0 {
  outline: none;
}

.bg-gray {
  background: #f0f0f5;
}

.tag-input .tag-ripple {
  border-radius: 12px !important;
  max-height: 24px !important;
  background: #F5F5F5 !important;
  color: #ffffff !important;
  padding: 0px 4px 0px 8px;
}
.tag-input .ng2-tag-input {
  background: #FFFFFF;
  border: 1px solid #707070 !important;
  border-radius: 4px;
  padding: 4px 4px !important;
}
.tag-input .tag-wrapper .tag__text {
  color: #555555;
  font-size: 13px !important;
  font-weight: 700;
}
.tag-input .tag-wrapper delete-icon svg path {
  fill: #555555;
}

.footer {
  right: 0px;
  left: 0px;
  background: #ffffff;
  color: #8e8fa6;
  border-top: 1px solid #dfe0e7;
  transition: all 0.5s ease-in-out;
  padding: 0.8rem;
  z-index: 999;
  position: absolute;
  left: 0px;
}

.accordion {
  background: #ffffff;
  border: 1px solid #dfe0e7;
  box-sizing: border-box;
}

.toast-container {
  top: 30px !important;
}

pre {
  white-space: break-spaces;
  word-break: break-all;
}

.toggle-view {
  transition: all 0.5s ease-in-out;
}
.toggle-view.collapsed-view {
  margin-left: 66px;
}
.toggle-view.expanded-view {
  margin-left: 280px;
}

.toggle-view-fixedBar {
  transition: all 0.5s ease-in-out;
}
.toggle-view-fixedBar.collapsed-view {
  margin-left: 66px;
}
.toggle-view-fixedBar.expanded-view {
  margin-left: 280px;
}

.content-container {
  margin-top: 60px;
}

.dropdown-list {
  z-index: 1000 !important;
}

.toast-container .toast {
  color: white !important;
  background-color: black !important;
  width: 381px !important;
  padding: 16px 0px 0px 0px;
  border-radius: 4px 0px 0px 0px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.24) !important;
  box-shadow: 0px 0px 10px 0px rgba(33, 34, 38, 0.12) !important;
}

.toast-success {
  color: white !important;
  background-color: black !important;
  width: 381px !important;
  padding: 16px 0px 0px 0px;
  border-radius: 4px 0px 0px 0px;
  background-image: url("/assets/images/success-tick.png") !important;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.24) !important;
  box-shadow: 0px 0px 10px 0px rgba(33, 34, 38, 0.12) !important;
}

.toast-error {
  color: white !important;
  background-color: black !important;
  width: 381px !important;
  padding: 16px 0px 0px 0px;
  border-radius: 4px 0px 0px 0px;
  background-image: url("/assets/images/error-circle.png") !important;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.24) !important;
  box-shadow: 0px 0px 10px 0px rgba(33, 34, 38, 0.12) !important;
}

.tooltip {
  opacity: 1 !important;
}
.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #000000 !important;
}
.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #000000 !important;
}
.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #000000 !important;
}
.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #000000 !important;
}
.tooltip .tooltip-inner {
  text-align: left !important;
  color: #E6E6E6 !important;
  font-size: 12px !important;
  line-height: 18px;
  background-color: #000000 !important;
}

router-outlet + * {
  display: block;
  height: 100%;
  width: 100%;
}

/*custom media queries*/
/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .w-xs-100 {
    width: 100% !important;
  }
  .w-xs-75 {
    width: 75% !important;
  }
  .w-xs-50 {
    width: 50% !important;
  }
  .w-xs-25 {
    width: 25% !important;
  }
}
/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .container-fluid {
    min-width: 1100px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
}
/*custom media queries*/
/*custom checkbox */
.custom-check {
  display: block;
  position: relative;
  padding-left: 18px;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 24px;
  width: 24px;
}

/* Hide the browser's default checkbox */
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #8E8FA6 !important;
}

/* On mouse-over, add a grey background color */
.custom-check:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.custom-check input:checked ~ .checkmark {
  border: 1px solid #005BF0;
  background-color: #005BF0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-check .checkmark:after {
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(90deg);
  transform: rotate(45deg);
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  left: 7px;
  top: 1px;
}

/*custom checkbox */
.custom-border-color {
  border-color: #ACADC1 !important;
}

.m-h-560px {
  max-height: 560px;
}

.m-h-420px {
  max-height: 420px;
}

.min-h-420px {
  min-height: 420px;
}

.h-350px {
  height: 350px;
}

.h-45px {
  height: 45px;
}

.w-97 {
  width: 97%;
}

.min-h-331px {
  min-height: 331px;
}

.max-h-331px {
  max-height: 331px;
}

.tl-fixed {
  table-layout: fixed;
}

.break-w {
  word-wrap: break-word;
}

.resize-none {
  resize: none;
}

.fs-small {
  font-size: small;
}

.btn-choose-img {
  width: 133px;
  color: blue;
  border: 1px solid blue;
}

.p-none {
  padding: none;
}

.table-avtar {
  height: auto;
  max-height: 40px !important;
  width: 40px;
  clip-path: circle(20px at center);
  /* margin: 6px 10px; */
}

.mt-5pc {
  margin-top: 5%;
}

.mt-20pc {
  margin-top: 20%;
}

.mt-60pc {
  margin-top: 60%;
}

.mr-100px {
  margin-right: 100px;
}

.w-50px {
  width: 50px;
}

.form-control:focus {
  box-shadow: none !important;
}

.edit-folder {
  top: -5px;
}

.ls-none {
  list-style: none;
}

.lh {
  line-height: 2.2;
}

.bg-white-smoke {
  background-color: #F8F8F8;
}

.pt-1px {
  top: 1px;
}

.pt-3px {
  top: 3px;
}

.daterangepicker {
  left: 700px !important;
  width: fit-content !important;
}

.daterangepicker .drp-calendar {
  max-width: 378px !important;
}

button,
input,
optgroup,
select,
.input-group > .input-group-append > .input-group-text {
  height: 40px !important;
}

.btn {
  line-height: 1 !important;
  min-width: 120px;
}
.btn.btn-sm {
  height: 40px !important;
}

.form-control,
.input-group-text {
  color: #26273B !important;
}

select.form-control {
  font-size: 16px;
  border: 1px solid rgb(187, 187, 187) !important;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #F5F5F5 100%) !important;
  color: #000000 !important;
}
select.form-control:disabled {
  color: rgb(112, 112, 112) !important;
  background: #fff !important;
}

.h-50px {
  height: 50px !important;
}

.btn-link {
  color: #005BF0 !important;
  font-weight: 600 !important;
}
.btn-link:hover {
  text-decoration: none !important;
}

.label-asterisk:after {
  content: "*";
  color: #F1736A;
  margin: 0px 0 0 5px;
}

.alert-secondary {
  color: #26273B !important;
  background-color: #F4F1F9 !important;
  border-color: rgba(148, 118, 201, 0.2) !important;
}

.outline-none {
  outline: none !important;
  box-shadow: none !important;
}

.outline-none:focus {
  outline: none !important;
  box-shadow: none !important;
}

.close:focus {
  outline: none !important;
}

@media (max-width: 768px) {
  .tl-fixed {
    table-layout: auto;
  }
  .collapsed-view {
    width: 100% !important;
  }
  .expanded-view {
    width: 100% !important;
  }
}
.btn.btn-purple,
.btn#sidebarToggle {
  min-width: auto;
}

.ngxclipboard input {
  font-size: 14px !important;
}

.minWidth-43 {
  min-width: 43px;
}

.ngxclipboard {
  border: 1px solid transparent !important;
  border-radius: 4px;
}
.ngxclipboard input {
  border: 1px solid transparent !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

input,
textarea {
  font-size: 14px !important;
}

.badge {
  background-color: #F5F5F5;
  font-weight: inherit;
  font-size: inherit;
  margin-right: 10px;
  color: #555555;
  font-size: 12px !important;
  font-weight: 700;
  height: 24px;
  min-width: 84px;
  display: flex;
  align-items: center;
  padding: 0px 4px 0px 8px;
}
.badge:hover {
  cursor: pointer;
}
.badge .badge-text {
  min-width: 70%;
  text-align: left;
}

.width-30-percent {
  width: 30% !important;
}

.width-250 {
  width: 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
}

.width-300 {
  width: 300px !important;
  max-width: 300px !important;
  min-width: 300px !important;
}

.width-335 {
  width: 335px !important;
  max-width: 335px !important;
  min-width: 335px !important;
}

.textEllipsis {
  width: 75%;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-segment-label,
.taskFolderTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block !important;
  max-width: 75%;
}

.taskTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block !important;
  max-width: 100%;
}

.height20px {
  height: 20px;
}

.height50px {
  height: 50px;
}

.height12px {
  height: 12px;
}

@media (min-width: 3840px) {
  .sidebar ul {
    font-size: 16px;
  }
  .sidebar li a,
  .sidebar li a.collapsed.active {
    height: 60px;
  }
  .btn {
    font-size: 16px !important;
  }
  input,
  select {
    font-size: 14px !important;
  }
}
.margin-auto {
  margin: auto;
}

* {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

table {
  background-color: #fff !important;
}

.calendar-table table thead,
.calendar-table table thead tr {
  box-shadow: none;
}

table.dataTable thead th {
  vertical-align: middle;
}

.minWidth-120 {
  min-width: 120px;
}

.minWidth-160 {
  min-width: 160px;
}

.dropdown-btn span:last-child {
  padding-right: 4px;
}

.textAlignCenter {
  text-align: center;
}

.tooltip {
  z-index: 1;
}

.infoIcon svg:not(:root) {
  width: 15px !important;
  height: 15px !important;
  margin-top: -3px !important;
}

.padding50 {
  padding-bottom: 50px;
}

.wrap {
  word-wrap: break-word;
}

.footer-logo-binary {
  position: absolute;
  bottom: 12px;
  right: 8px;
}

.borderR {
  border-right: 1px solid grey;
}

.borderL {
  border-left: 1px solid grey;
}

.progress {
  width: 192px;
  height: 4px !important;
}

.progress-bar {
  background-color: #005BF0 !important;
  border-radius: 4px !important;
}

.tooltip-width {
  width: 280px !important;
}
.tooltip-width .tooltip-inner {
  max-width: 720px !important;
}

.move-left-16 {
  left: 16px !important;
}

.component-section {
  background: white;
  border-radius: 4px;
  margin-top: 30px;
}

.sectionHeader {
  font-size: 20px;
  line-height: 1;
  font-weight: 900;
  color: #26273B;
}

.refresh-section {
  float: right;
  margin-top: 1px;
  cursor: pointer;
}

.sectionBody {
  padding: 16px;
  display: flow-root;
}

.marginTop--10 {
  margin-top: -10px;
}

.marginTop-30 {
  margin-top: 30px;
}

.data-wrapper {
  display: inline-flex;
  height: 100%;
  align-items: center;
}

.overflow-x-table {
  overflow-x: auto;
}

.overflow-x-table table {
  width: 110% !important;
}

tbody tr#labels td:first-child {
  background-color: transparent !important;
}

tr#labels td .card {
  background-color: transparent !important;
}

.supportLink {
  color: #005BF0 !important;
  text-decoration: underline !important;
  font-size: 14px;
  font-weight: 600;
}

.section-header {
  display: flex;
  align-items: start;
  height: 40px;
  margin-bottom: 20px;
}
.section-header .section-title {
  text-align: left;
  font-size: 20px;
  font-weight: 900;
  color: #26273B;
}

.table-container {
  border-radius: 0.5%;
  border-radius: 4px;
}

.displayFlex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.primaryFont-color {
  color: #005BF0;
}

.fontWeight-700 {
  font-weight: 700;
}

.fontWeight-900 {
  font-weight: 900;
}

table tr {
  min-height: 56px;
  height: 56px;
}

.list-sidebar > li {
  font-size: 16px;
}

.list-sidebar > .submenu-guides > li {
  font-size: 14px !important;
}

.cls-2 {
  fill: #555555 !important;
}

.cls-1 {
  fill: rgb(255, 255, 255) !important;
}

.switch-disabled input {
  display: none;
}
.switch-disabled input.primary:checked + .slider {
  background-color: #BDD6FF;
  cursor: default !important;
}
.switch-disabled input.primary:not(:checked) + .slider {
  cursor: default !important;
  background-color: #BBBBBB;
}

#tableCust thead {
  border: none !important;
}

.displayBlock {
  display: block;
}

.tag-wrapper {
  line-height: 2 !important;
}
.tag-wrapper delete-icon {
  margin-top: -5px !important;
}
.tag-wrapper delete-icon svg path {
  fill: #555555 !important;
}

.font-light-color {
  color: #555555;
}

tag {
  background-color: #F5F5F5 !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  margin-right: 10px !important;
  color: #555555;
  font-size: 12px !important;
  font-weight: 700 !important;
  height: 24px !important;
  min-width: 84px;
  display: flex !important;
  align-items: center !important;
  padding: 0px 4px 0px 8px !important;
}

.input-radio-custom {
  width: 24px !important;
  height: 24px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #383838;
  border-radius: 50%;
  cursor: pointer;
  outline: 0;
  padding: 4px !important;
  background-clip: content-box;
}

.input-radio-custom:hover {
  border: 1px solid #BBBBBB;
  background-color: #005BF0;
  opacity: 0.5;
}

.input-radio-custom:checked {
  border: 2px solid #005BF0;
  background-color: #005BF0;
}

.input-radio-custom:focus {
  border: 1px solid #005BF0;
}

.ng2-tag-input .ng2-tags-container {
  align-items: center !important;
}

.ng2-tag-input__text-input {
  font-size: 16px !important;
  color: #707070;
}

select.form-control {
  box-shadow: none !important;
  appearance: none !important;
  /* Removes the default dropdown arrow */
  -webkit-appearance: none !important;
  /* For Safari */
  -moz-appearance: none !important;
  /* For Firefox */
  padding-right: 30px !important;
  /* Adds padding to the right side */
  background-color: white !important;
  border: 1px solid #bbbbbb !important;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F5F5F5 100%), url('data:image/svg+xml;utf8,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 10.332L17.59 9L13 13.3266L8.41 9L7 10.332L13 16L19 10.332Z" fill="%231C1D21"/></svg>') no-repeat right 6px top 8px/26px !important;
}

input.form-control:focus,
textarea.form-control:focus {
  border: 2px solid #005FCC !important;
}

.search {
  background: url("../src/assets/svgs/search-icon-new.svg");
  background-position: 15px center;
  background-repeat: no-repeat;
  padding-right: 12rem !important;
}

.search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input::placeholder {
  padding-left: 0px !important;
}

input[type=search] {
  padding-left: 45px;
  border: 1px solid #bbbbbb;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #707070 !important;
  line-height: 24;
  padding-right: 10px !important;
}

.zIndex-3 > .main-drop-div {
  position: initial !important;
}

/**
.multiselect-dropdown {
    .dropdown-btn {
        border: 1px solid #acadc1 !important;
        padding: 6px 8px !important;

        &:focus {
            border-color: $theme-color !important;
        }
    }

    .selected-item {
        background: $theme-color !important;
        border-color: $theme-color !important;
        margin: 2px 4px 2px 2px;
        font-size: 12px;
        font-weight: 400;
        padding: 2px 6px !important;
    }

    .dropdown-down {
        border-top: 8px solid #394042 !important;
        border-left: 8px solid transparent !important;
        border-right: 8px solid transparent !important;
    }

    .dropdown-up {
        border-bottom: 8px solid #394042 !important;
        border-left: 8px solid transparent !important;
        border-right: 8px solid transparent !important;
    }

    .multiselect-item-checkbox {
        input[type="checkbox"]+div {
            &:before {
                border-color: $theme-color !important;
                border-radius: 3px;
                border: 1px solid $theme-color !important;
            }
        }

        input[type="checkbox"]:checked+div {
            &:before {
                background: $theme-color !important;
                border: 1px solid $theme-color !important;
            }
        }

        input[type="checkbox"]:checked+div {
            &:after {
                left: 3px !important;
            }
        }
    }
}

.multiselect-dropdown .dropdown-btn {
    max-height: 35px !important;
}

.multiselect-dropdown .dropdown-btn {
    display: flex !important;
    align-items: center !important;
}

.multiselect-dropdown .dropdown-btn span:last-child {
    // float: right !important;
    padding-right: 4px;
    // position: absolute;
    // right: 10px;
}

.multiselect-dropdown .dropdown-btn span:last-child {
    // float: right !important;
    padding-right: 4px;
    // position: absolute;
    // right: 10px;
    display: flex;
    top: 14px;
}

.multiselect-dropdown .dropdown-btn .dropdown-down:last-child,
.multiselect-dropdown .dropdown-btn .dropdown-up:last-child,
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    position: relative;
    // right: 0px;
    top: 3px !important;
    padding: 0px;
}

**/